











import { Vue, Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { apiBuilder } from '../../config'
import axios from 'axios'
import dayjs from 'dayjs'

const auth = namespace('auth')

@Component
export default class OrderHistory extends Vue {
	@auth.State
	private token!: string
	@auth.State
	private username!: string

	private loading = true

	private tableItem = {
		header: [
			{
				text: 'Mã',
				sortable: false,
				value: 'transactionID',
				align: 'center',
			},
			{
				text: 'Số Tiền',
				value: 'money',
				sortable: false,
				align: 'center',
			},
			{
				text: 'Thời Gian',
				value: 'time',
				sortable: false,
				align: 'center',
			},
		],
		items: [],
	}

	private getTransactionHistory(): void {
		this.loading = true
		const apiUrl = apiBuilder('transaction/transactionhistory.php')

		const param = {
			params: {
				username: this.username,
				token: this.token,
			},
		}

		axios
			.post(apiUrl, null, param)
			.then((res) => {
				// console.log(res.data)

				const error = res.data.error

				if (error == 331) {
					sessionStorage.setItem('redirectPath', window.location.pathname)
					return this.$router.replace({ name: 'Login' })
				} else if (error != 330) {
					// console.log(error)

					this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Server Error! Please Reload !',
					})
				} else {
					const data = res.data.data

					// eslint-disable-next-line
					this.tableItem.items = data
						.map((x: any) => {
							return {
								transactionID: x.id,
								money: x.opcode + ' ' + new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(x.value),
								opcode: x.opcode,
								time: dayjs.unix(x.created_at).format('DD/MM/YYYY HH:mm:ss'),
							}
						})

					this.loading = false
				}
			})
			.catch((e) => {
				this.$swal({
					icon: 'error',
					title: 'Lỗi !',
					text: e,
				})
			})
	}

	created() {
		this.getTransactionHistory()
	}
}
